/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from '../../../customAxios';
import {
  Col, Card, CardBody,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../env';
import EditOutlineIcon from 'mdi-react/EditOutlineIcon'
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import AddonMainCourt from './AddonMainCourt';

const ListAddonsEditCard = () => {
  const [addonList, setAddonList] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [role, setRole] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [mainCourtList, setMainCourtList] = useState([]);

  const addonListUrl = `${environment.baseUrl}addon`;
  const navigate = useNavigate();
  const getAddons = async () => {
    const result = await axios(addonListUrl);
    setAddonList(result.data);
  };

  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);      
    });
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-list`;
    await axios.get(mainCourtUrl).then((response) => {
      setMainCourtList(
        response?.data.map((court) => ({
          label: court.courtName,
          value: court.id,
        }))
      );
    });
  };

  useEffect(() => {
    getRole();
    if (localStorage.getItem("Token")) {
      
    } else {
      alert("You are not logged In. Please LogIn");
      navigate("/");
    }
    getAddons();
    GetMainCourt();
  }, []);

  


  const setPermissions = (roledata) => {
    const localrole = localStorage.getItem("Role");
    let newRoleId;
    

    if (localrole === roledata.role_name) {
      
      let permission = JSON.parse(roledata.permissions);
      
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "add_on"
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } 
    
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
    
  };

  var jsonObj = [];
   
  for(let i=0; i<addonList.length; i++){
    var item = {}

    // item["id"] = i+1;
    item["id"] = i+1;
    item["addonname"] = <div className="right-space">{addonList[i].addonname}</div>;
    item["price"] = <div className="right-space">{addonList[i].price}</div>;
    item["description"] = <div className="right-space">{addonList[i].description}</div>;
    item["maincourt"] = <AddonMainCourt mainCourtList={mainCourtList} addonMainCourt={addonList[i].maincourt_availability} />;
    if(deleteing === true) {
      item["link"] = <div className="right-space"><div className="modal-edit-btn" size="sm" onClick={()=>handleEdits(addonList[i].id)}><EditOutlineIcon style={{fontSize:20}}/></div></div>;
      // item["delete"] = <div className="right-space"><div className="modal-delete-btn" size="sm" onClick={()=>handleDelete(addonList[i].id)}><DeleteOutlineIcon style={{fontSize:20}} /></div></div>;
    }
    else if(editing === true){
      item["link"] = <div className="right-space"><div className="modal-edit-btn" size="sm" onClick={()=>handleEdits(addonList[i].id)}><EditOutlineIcon style={{fontSize:20}}/></div></div>;
    }
    jsonObj.push(item);
}

const data = {
  columns: [
    {
      Header: '#',
      accessor: 'id',
      disableGlobalFilter: true,
      width: 40,
    },
    {
      Header: "Name",
      accessor: "addonname",
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: "Price",
      accessor: "price",
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: "Description",
      accessor: "description",
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: "Main Court",
      accessor: "maincourt",
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: "Edit",
      accessor: "link",
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 30,
    },
    // {
    //   Header: "Delete",
    //   accessor: "delete",
    //   disableGlobalFilter: true,
    //   disableSortBy: true,
    //   width: 30,
    // },
  ],
  rows: jsonObj,
};

const handleDelete = (id) => {
  const addonDeleteUrl = `${environment.baseUrl}addon/${id}`;
  axios.delete(addonDeleteUrl)
  setAddonList(() => addonList.filter((todo) => todo.id !== id));
};

const handleEdits = (id) => {
    const addonsEditUrl = `/edit_addons/${id}`;
    navigate(addonsEditUrl);
};

  const handleAddAddons = () => {
    navigate("/court_addons");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    // withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };

  return (
    <>
        <Col md={12} lg={12}
        style={{
              justifyContent:'flex-end',
              display:'flex',
              alignItems:'flex-end',
              }}>
          {deleteing ? (
            <div className="justpadel-buttons btn-position" onClick={() => handleAddAddons()}>Add Addon</div>
          ) : (
            ""
          )}
        </Col>
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          <div className="hover-effect table-id-width">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
          
        </CardBody>
      </Card>
    </Col>
    </>
  );
};
export default ListAddonsEditCard;
