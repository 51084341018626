/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Alert, Button } from 'reactstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { environment } from '../../env';
import 'react-calendar/dist/Calendar.css';
import { useSelector, useDispatch } from 'react-redux'
import { logged } from '../../../redux/slice/AuthSlice';

const LogInForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [passwords, setPasswords] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [fieldUser, setFieldUser] = useState('Username');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleUsernameChange(e) {
    setUserName(e.target.value);
  }
  function handlePasswordChange(e) {
    setPasswords(e.target.value);
  }

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const loginUrl = `${environment.baseUrl}api/user/login/`;

  const handleSignIn = () => {
    const loginCredentials = {
      email: userName,
      password: passwords,
    };
    axios.post(loginUrl, loginCredentials)
      .then(async (response) => {
        if (response.data.status === 200) {
          localStorage.setItem('Role', response.data.role);
          localStorage.setItem('Email', userName.toLowerCase());
          localStorage.setItem("UserId", response.data.userid);
          localStorage.setItem('Token', response.data.access);
          localStorage.setItem('AdminUserName', response.data.username);
          localStorage.setItem('loggedUserDetails', JSON.stringify(response.data));
          dispatch(logged(response.data));
          if (response.data.role !== 'customer') {
            if (response.data.role !== 'counterbooking' && response.data.role !== 'brightlearner' && response.data.role !== 'brightonadmin') {
              if (response.data.role == "Hattapadel") {
                navigate('/tournaments');
              } else if (response.data.role === 'coaches'){
                navigate('/verify-skill-level')
              } else {
                navigate('/bookings');
              }
            } else if (response.data.role === 'brightlearner') {
              navigate('/bookings');
            }
            else if (response.data.role === 'brightonadmin') {
              navigate('/bookings');
            }
            else {
              navigate('/bookings');
            }
          } else {
            navigate('/');
          }
        } else {
          navigate('/');
          setLoginErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log("🚀 ~ file: LogInForm.jsx:75 ~ handleSignIn ~ error:", error)
        setLoginErrorMessage('Please Provide a Valid Credentials');
      });
  };

  useEffect(() => {
    if (localStorage.getItem('Token')) {
    } else {
      // alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);

  return (
    <div className="form login-form">
      <Alert
        color="danger"
        style={{ width: '100%' }}
        isOpen={!!loginErrorMessage}
      >
        {loginErrorMessage}
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label" style={{ fontFamily: 'sans-serif', fontSize: 15 }}>{fieldUser}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <input
            name="username"
            component="input"
            placeholder={fieldUser}
            className="input-without-border-radius"
            onChange={handleUsernameChange}
          />
        </div>
      </div>
      <div className="form__form-group" style={{ marginTop: 10 }}>
        <span className="form__form-group-label"
          style={{ fontFamily: 'sans-serif', fontSize: 15, borderRadius: 5 }}>
          Password
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <input
            name="password"
            component="input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            className="input-without-border-radius"
            onChange={handlePasswordChange}
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={showPasswordToggle}
          ><EyeIcon />
          </button>
          <div className="account__forgot-password text-danger">
            <Link to="/verify_email">Forgot a password?</Link>
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <div className="form__form-group form__form-group-field">
          {/* <input
            name={`remember_me-${form}`}
            component={renderCheckBoxField}
            label="Remember me"
          /> */}
        </div>
      </div>
      <div className="form__form-group" style={{ marginTop: 30 }}>
        <Button className="account__btn" color="warning" onClick={() => handleSignIn()}>Sign In</Button>

        <Link className="btn btn-outline-primary account__btn" to="/register" style={{ display: 'none' }}>Create
          Account
        </Link>
      </div>
    </div>
  );
};

export default LogInForm;
