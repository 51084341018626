/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, Button, Card, CardBody, Row,
} from 'reactstrap';
import axios from '../../../customAxios';

import { useNavigate, Link } from "react-router-dom";
import { environment } from '../../env';
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';

const ListCourtCategoryEditCard = () => {
  const [allCategory, setallCategory] = useState([]);
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [role, setRole] = useState();
  const categoryUrl = `${environment.baseUrl}category/`;
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const getCategory = async () => {
    const result = await axios(categoryUrl);
    setallCategory(result.data);
  };

  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);
    });
  };

  useEffect(() => {
    getRole();
    if (localStorage.getItem("Token")) {
    } else {
      alert("You are not logged In. Please LogIn");
      navigate("/");
    }

    getCategory();
  }, []);

  const setPermissions = (roledata) => {
    const localrole = localStorage.getItem("Role");
    let newRoleId;

    if (localrole === roledata.role_name) {
      let permission = JSON.parse(roledata.permissions);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "court_category"
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
    }
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
  };

  var jsonObj = [];

  for (let i = 0; i < allCategory.length; i++) {
    var item = {}
    item["id"] = i + 1;
    item["courtcategory"] = <div className="right-space">{allCategory[i].courtcategory}</div>;
    item["percentage_of_discount"] = <div className="right-space">{allCategory[i].percentage_of_discount}</div>;
    if (deleteing === true) {
      item["link"] = <div className="right-space"><div className="modal-edit-btn" onClick={() => handleEdit(allCategory[i].id)}><AiOutlineEdit style={{ fontSize: 20 }} /></div></div>;
      // item["delete"] = <div className="right-space"><div className="modal-delete-btn" onClick={()=>handleDelete(allCategory[i].id)}><AiOutlineDelete  style={{fontSize:20}} /></div></div>;
    } else if (editing === true) {
      item["link"] = <div className="right-space"><div className="modal-edit-btn" onClick={() => handleEdit(allCategory[i].id)}><AiOutlineEdit style={{ fontSize: 20 }} /></div></div>;
    }
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Court Category",
        accessor: "courtcategory",
        disableGlobalFilter: true,
        width: 200,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      // {
      //   Header: "Delete",
      //   accessor: "delete",
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      // },
    ],
    rows: jsonObj,
  };
  const handleDelete = (id) => {
    const categoryDeleteUrl = `${environment.baseUrl}category/${id}/`;
    axios.delete(categoryDeleteUrl).then((response) => {
    });
    setallCategory(() => allCategory.filter((todo) => todo.id !== id));
  };

  function handleEdit(courtTypeId) {
    navigate(`/edit_court_category/${courtTypeId}`);
  }

  const handleAddCourtCategory = () => {
    navigate("/court_category");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>

          <div className="hover-effect table-id-width">
            <ReactTableBase
              key={withSearchEngine ? 'searchable' : 'common'}
              columns={data.columns}
              data={data.rows}
              tableConfig={tableConfig}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ListCourtCategoryEditCard;
