import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, ButtonToolbar } from "reactstrap";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { environment } from "../env";
import axios from "../../customAxios";
import S3FileUpload from "react-s3";

const config = {
    bucketName: "justpadel-courtimages",
    region: "us-east-1",
    accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
    secretAccessKey: "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",
};

// const s3optionsImage = {
//     keyprefix: "padelgram-images",
//     bucket: "padelgram-media",
//     region: "ap-south-1",
//     accessKey: "AKIAZZLUGKQZ7LP7TWHK",
//     secretKey: "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",
//     successActionStatus: 201,
//   };

const UploadPost = () => {

    const [mainCourtList, setMainCourtList] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const filteredMainCourt = mainCourtList.filter((data) => (data.id !== 12));
    const LoggedUserId = localStorage.getItem("UserId");

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();

    const isLessThanTheMB = (fileSize, smallerThanSizeMB) => {
        const isOk = fileSize / 1024 / 1024 < smallerThanSizeMB
        return isOk;
    }


    const onSubmit = (data) => {
        setButtonDisabled(true);
        const isLt8MB = isLessThanTheMB(data.image[0].size, 8)
        if (!isLt8MB) {
            alert(`Image size must be smaller than 8MB!`)
            setButtonDisabled(false);
            return
        } else {
            setButtonDisabled(true);
            S3FileUpload.uploadFile(data.image[0], config)
                .then((res) => {

                    if (res.result.status == 204) {
                        const postPayload = {
                            location: data.location,
                            padelgram_image: res.location,
                            padelgram_image_comment: data.caption,
                            user: LoggedUserId,
                            post_type: "public",
                        };

                        const createPostUrl = `${environment.baseUrl}padelgram/padelgram-post/`;
                        axios.post(createPostUrl, postPayload).then((response) => {
                            setButtonDisabled(false);
                            swal({
                                title: "Success",
                                text: "Post Uploaded successfully!",
                                icon: "success",
                                button: "Ok",
                            }).then((value) => {

                                navigate("/live-post");
                            });
                        });

                    } else {
                        setButtonDisabled(false);
                        console.log("response error", res);
                    }
                })
                .catch((err) => {
                    setButtonDisabled(false);
                });
        }
    };


    const GetMainCourt = async () => {
        const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
        const result = await axios.get(mainCourtUrl);
        setMainCourtList(result?.data);
    };

    useEffect(() => {
        GetMainCourt();
    }, [])


    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Upload Post</h3>
                </Col>
            </Row>
            <Row>
                <Card>
                    <CardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm="6">
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Caption</span>
                                        <div className="form__form-group-field">
                                            <input
                                                type="text"
                                                name="caption"
                                                className="form-control"
                                                placeholder="Write a caption . . ."
                                                {...register("caption", { required: true })}
                                            />
                                        </div>
                                        {errors.caption && (
                                            <span className="error-msg">Caption Is required</span>
                                        )}
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Image</span>
                                        <div className="form__form-group-field">
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="image"
                                                accept="image/png, image/jpeg"
                                                {...register("image", { required: true })}
                                            />
                                        </div>
                                        {errors.image && (
                                            <span className="error-msg">Image Is required</span>
                                        )}
                                    </div>
                                </Col>
                                <Col sm={6} md={6} lg={6}>
                                    <div className="form product-edit">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Location
                                            </span>
                                            <div className="form__form-group-field">
                                                <select
                                                    className="input1-div"
                                                    name="location"
                                                    {...register("location", {
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">Add Location</option>
                                                    {filteredMainCourt &&
                                                        filteredMainCourt.map((court) => (
                                                            <option value={court.courtName}>{court.courtName}</option>
                                                        ))}{" "}
                                                </select>
                                            </div>
                                            {errors.location && (
                                                <span className="error-msg">
                                                    {" "}
                                                    Please Enter Your Location
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="form__form-group">
                                        <ButtonToolbar className="form__button-toolbar">
                                            <button
                                                className={"justpadel-buttons-save mt-2"}
                                                type="submit"
                                                disabled={buttonDisabled}
                                            >
                                                Submit
                                            </button>
                                        </ButtonToolbar>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    )
}

export default UploadPost