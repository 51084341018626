import React, { useState, useEffect } from 'react';
import {
  Col, Button, ButtonToolbar, Card, CardBody
} from 'reactstrap';
import axios from '../../../customAxios';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../env';
import swal from "sweetalert";

const CreateUser = () => {
  const [name, setName] = useState();
  const [passwords, setPassword] = useState();
  const [emails, setEmail] = useState();
  const [sources, setSource] = useState();
  const [roles, setRole] = useState();
  const [roleResponse, setRoleResponse] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(true);
  const [reload, setReload] = useState(false);
  const [userRole, setUserRole] = useState();

  function handleChangeName(e) {
    setName(e.target.value);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);
  }
  function handleChangeEmail(e) {
    setEmail(e.target.value);
  }
  function handleChangeSource(e) {
    setSource(e.target.value);
  }
  function handleChangeRole(e) {
    setRole(e.target.value);
  }
  const navigate = useNavigate();
  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);
      console.log("response", response.data);
    });
  };

  const getRoles = () => {
    const getRoleUrl = `${environment.baseUrl}role`;
    const userRoles = axios
      .get(getRoleUrl)
      .then((response) => {
        setRoleResponse(response.data);
        console.log("All Roles", response.data);
      })
      .catch((err) => {
        console.log("Error" + err);
      });
    console.log(userRoles);
  };

  useEffect(() => {
    getRoles();
    getRole();
    setUserRole(localStorage.getItem("Role"));
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);

  const setPermissions = (roledata) => {
    // const localrole = "admin";
    const localrole = localStorage.getItem("Role");
    console.log("Local Added Role", localrole);
    let newRoleId;
    console.log(roledata.role_name);

    if (localrole === roledata.role_name) {
      console.log(roledata.permissions);
      let permission = JSON.parse(roledata.permissions);
      console.log(permission);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "manual_bookings"
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }
    console.log("Role Id", newRoleId);
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
    console.log(editing);
  };

  const handleCustomerDetails = () => {
    const customerDetails = {
      first_name: name,
      password: passwords,
      email: emails,
      source: sources,
      role: roles,
    };


    console.log("Customer Details", customerDetails);

    const verifyEmail = `${environment.baseUrl}email/`;
    const verifyEmailResult = axios.post(verifyEmail, customerDetails)
      .then((response) => {
        if (response.data.Message === 'Email already Exists') {
          swal({
            title: "Email already exist!",
            icon: "error",
          });
        } else {
          const createUserUrl = `${environment.baseUrl}create_user`;
          const result = axios.post(createUserUrl, customerDetails)
            .then((response) => {
              if(response.data.message === 'Registration Sucessfull Done'){
                swal({
                  title: "User is created !",
                  icon: "success",
                })
                .then(()=>{
                  reset();
                })
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
    
  };
  const reset = () => {
    setName('');
    setPassword('');
    setEmail('');
    setSource('');
    setRole('');
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          {deleteing ?
            <form className="form product-edit" style={{ display: "flex", flexDirection: "column" }} >
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label" style={{ fontSize: 15 }}>Name</span>
                  <div className="form__form-group-field">
                    <input type="text" value={name} onChange={handleChangeName} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                  </div>
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label" style={{ fontSize: 15 }}>Email</span>
                  <div className="form__form-group-field">
                    <input type="email" value={emails} onChange={handleChangeEmail} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ paddingTop: 20 }} >
                <div className="form__form-group">
                  <span className="form__form-group-label" style={{ fontSize: 15 }}>Password</span>
                  <div className="form__form-group-field">
                    <input type="password" value={passwords} onChange={handleChangePassword} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ paddingTop: 20 }}>
                <div className="form__form-group">
                  <span className="form__form-group-label" style={{ fontSize: 15 }}>Source</span>
                  <div className="form__form-group-field">
                    <select
                      className="form__form-group-fields select"
                      value={sources}
                      onChange={handleChangeSource}
                    >
                      <option value="">Select Option</option>
                      <option value="website">Web</option>
                      <option value="app">App</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ paddingTop: 20 }}>
                <div className="form__form-group">
                  <span className="form__form-group-label" style={{ fontSize: 15 }}>RDE</span>
                  <div className="form__form-group-field">
                    <select
                      className="form__form-group-fields select"
                      value={roles}
                      onChange={handleChangeRole}
                    >
                      <option value="">Select Role</option>
                      {
                        roleResponse.map((data, key) => {
                          return (
                            <option value={data.role_name}>{data.role_name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <Col md={6} lg={6}>
                <Card style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", marginTop: 30, }}>
                  <div className="form__half" style={{ marginRight: 0 }}>
                    <ButtonToolbar className="form__button-toolbar" >
                      <div className='justpadel-buttons-save' onClick={handleCustomerDetails}>
                        Submit
                      </div>
                      <div className='justpadel-buttons-cancel' onClick={reset}>
                        Reset
                      </div>
                    </ButtonToolbar>
                  </div>
                </Card>
              </Col>

            </form>
            :
            <h2>You Don't have permissions to access this page</h2>
          }
        </CardBody>
      </Card>
    </Col>
  )
};

export default CreateUser;
