import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
  Button,
  Row,
  Label,
  Input,
} from "reactstrap";
import {
  GetMainCourtForPromotion,
  AddPromotionApi,
  GetPadelCourt,
} from "../../Reuse/ApiCalls";
import { useForm, Controller } from "react-hook-form";
import swal from "@sweetalert/with-react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { environment } from "../../env";
import axios from "../../../customAxios";

const BulkPromotion = () => {
  const [mainCourtList, setMainCourtList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data) => {
    const courtValues = [];
    selectedOption.map((data) => {
      courtValues.push(data.value);
    });
    data.mainCourtLists = courtValues;
    const bulkPromotionCreateURL = `${environment.baseUrl}bulk-promotion/`;
    axios.post(bulkPromotionCreateURL, data).then((responseOfBulkPromotion) => {
      if (responseOfBulkPromotion.data.Message === "Promotion is created") {
        swal({
          icon: "success",
          content: (
            <div>
              <h1 style={{ color: "#28a745" }}>
                Promotion is created sucessfully
              </h1>
            </div>
          ),
        }).then(() => {
          navigate("/promotion");
        });
      }
    });
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
    await axios.get(mainCourtUrl).then((response) => {
      setMainCourtList(
        response?.data.map((court) => ({
          label: court.courtName,
          value: court.id,
        }))
      );
    });
  };

  useEffect(() => {
    GetMainCourt();
  }, []);

  const Back = () => {
    navigate("/promotion");
    {
      reset({ date: "", price: "", maincourt_id: "" });
    }
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-4">
              <Col sm="12" md="12" lg="12">
                <Label>MainCourt Name</Label>
                <Select
                  defaultValue={selectedOption}
                  isMulti
                  onChange={setSelectedOption}
                  options={mainCourtList}
                  placeholder={"Select Courts"}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col sm="6" md="6" lg="6">
                <Label>Start Date</Label>
                <input
                  type="date"
                  name="date"
                  id=""
                  {...register("startdate", { required: false })}
                  className="form-control"
                />
                {errors.date && (
                  <span className="error-msg">Date Is required</span>
                )}
              </Col>
              <Col sm="6" md="6" lg="6">
                <Label>End Date</Label>
                <input
                  type="date"
                  name="date"
                  id=""
                  {...register("enddate", { required: false })}
                  className="form-control"
                />
                {errors.date && (
                  <span className="error-msg">Date Is required</span>
                )}
              </Col>
            </Row>

            <Row className="mt-4">
              <Col sm="6" md="6" lg="6">
                <Label>Price</Label>
                <input
                  type="number"
                  name="price"
                  id=""
                  {...register("price", { required: false })}
                  className="form-control"
                />
                {errors.price && (
                  <span className="error-msg">Price Is required</span>
                )}
              </Col>
              <Col sm="6" md="6" lg="6">
                <Label>Additional Slot Price</Label>
                <input
                  type="number"
                  name="additional_slot_price"
                  id=""
                  {...register("additional_slot_price", { required: false })}
                  className="form-control"
                />
                {errors.additional_slot_price && (
                  <span className="error-msg">
                    Additional Slot Price Is required
                  </span>
                )}
              </Col>
            </Row>

            <ButtonToolbar className="mt-5 mb-3 mr-3 d-flex justify-content-end">
              <button className="justpadel-buttons-save" type="submit">
                Submit
              </button>
              <div className="justpadel-buttons-cancel" onClick={() => Back()}>
                Cancel
              </div>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default BulkPromotion;
