import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Label } from "reactstrap";
import { environment } from "../../env";
import axios from "../../../customAxios";
import { useNavigate, useParams } from "react-router-dom";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import {
  GetMainCourt,
  GetPadelCourt,
  GetRegisteredUser,
} from "../../Reuse/ApiCalls";
import { useForm } from "react-hook-form";
import UserBookingDetailsByEmail from "../../BookingDetails/UserBookingDetailsByEmail";
import UserBookingDetailsByUserId from "../../BookingDetails/UserBookingDetailsByUserId";
import "./UserDetailsCard.css";
import moment from "moment";
import swal from "sweetalert";
import UserPackageTransactionDetails from "./UserPackageTransactionDetails";
import Padelholic from './../../Padelholic/index';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const UserDetailsCard = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [userFavourites, setUserFavourites] = useState([]);
  const [isSortable, setIsSortable] = useState(false);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [adminSpinner, setAdminSpinner] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [mainCourtId, setMainCourtId] = useState("");
  const [registerdUser, setRegisterdUser] = useState([]);
  const [mainCourt, setMainCourt] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();

  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
    reset: reset3,
  } = useForm();

  const [padelCourtList, setPadelCourtList] = useState();
  const [userId, setUserId] = useState("");
  const [userBookingData, setUserBookingData] = useState([]);
  const [userAdminBookingData, setUserAdminBookingData] = useState([]);
  const [availableSlots, setAvailableSlots] = useState("");
  const [addSlots, setAddSlots] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [loyaltyLogs, setLoyaltyLogs] = useState([]);
  const [userLoyaltyPointData, setUserLoyaltyPointData] = useState()
  const [padelholicLevel, setPadelholicLevel] = useState('Silver')



  const { id } = useParams();
  const navigate = useNavigate();
  let limit = parseInt(localStorage.getItem("BookingListCurrentPageLimit"));
  const getUserDetailsUrl = `${environment.baseUrl}user/${id}`;
  let data = JSON.parse(localStorage.getItem("searchdata"));
  const getBookingUrl = `${environment.baseUrl
    }search-courtbooking?created_at_after=${data?.created_at_after ? data?.created_at_after : ""
    }&created_at_before=${data?.created_at_before ? data?.created_at_before : ""
    }&booking_date_after=${data?.booking_date_after ? data?.booking_date_after : ""
    }&booking_date_before=${data?.booking_date_before ? data?.booking_date_before : ""
    }&main_court=${mainCourtId ? mainCourtId : ""}&padel_court=${data?.padel_court ? data?.padel_court : ""
    }&booking_id=${data?.booking_id ? data?.booking_id : ""}&user=${userDetails?.email
    }&payment_status=${data?.payment_status ? data?.payment_status : ""
    }&page=1&page_size=10`;
  const getUserBookingUrl = `${environment.baseUrl}get_booking_user/${id}`;
  const getFavouritesUrl = `${environment.baseUrl}get_favorites/${id}`;

  const getUserBookingData = async () => {
    setSpinner(true);
    const result = await axios
      .get(getUserBookingUrl)
      .then((response) => {
        if (response) {
          setSpinner(false);
          setUserBookingData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserAdminBookingData = async (email) => {
    setAdminSpinner(true);
    const getUserAdminBookingUrl = `${environment.baseUrl}admin-booking-email/${email}`;

    const result = await axios
      .get(getUserAdminBookingUrl)
      .then((response) => {
        if (response) {
          setAdminSpinner(false);
          setUserAdminBookingData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserLoyaltyPoints = async (id) => {
    const result = await axios.get(`${environment.baseUrl}loyaltypoint/getloyaltypoints/${id}`).then((res) => {
      setUserLoyaltyPointData(res?.data)
      if (res?.data?.loyaltygames >= 0 && res?.data?.loyaltygames <= 30) {
        setPadelholicLevel('Silver')
      } else if (res?.data?.loyaltygames >= 31 && res?.data?.loyaltygames <= 50) {
        setPadelholicLevel('Gold')
      } else if (res?.data?.loyaltygames >= 51 && res?.data?.loyaltygames <= 100) {
        setPadelholicLevel('Platinum')
      } else if (res?.data?.loyaltygames >= 101) {
        setPadelholicLevel('BlackDiamond')
      }
    })
  }

  const getUserDetails = async () => {
    const result = await axios
      .get(getUserDetailsUrl)
      .then((response) => {
        setUserDetails(response?.data);
        getUserLoyaltyPoints(response?.data?.id)
        getUserAdminBookingData(response?.data?.email);
        getUserBookingDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserBookingDetails = () => {
    const result = axios(getBookingUrl)
      .then((response) => {
        setUserBookingData(response?.data?.results);
        setpageCount(Math.ceil(response?.data?.count / limit));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserFavouritesDetails = () => {
    const result = axios
      .get(getFavouritesUrl)
      .then((response) => {
        setUserFavourites(response?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetPackagesDetails = async () => {
    const GetPackages = `${environment.baseUrl}user-package/${id}/`;
    const result = await axios.get(GetPackages).then((response) => {
      setAvailableSlots(response.data.available_slots);
    });
  };

  const GetPackagePurchase = async () => {
    var rawTransactions = [];
    const GetPackagePurchaseUrl = `${environment.baseUrl}package-purchase/${id}/`;
    const result = await axios.get(GetPackagePurchaseUrl).then((response) => {
      rawTransactions = response.data.map((trans) => {
        if (trans.package !== "booking") {
          trans.package = JSON.parse(trans.package);
        }
        return trans;
      });
      setPackagesData(rawTransactions);
    });
  };

  const Loyaltypoints = () => {
    const GetLoyaltypointsUrl = `${environment.baseUrl}loyaltypoint/getloyaltyhistory/${id}`;
    const result = axios.get(GetLoyaltypointsUrl).then((res) => {
      setLoyaltyLogs(res.data);
    })
  };

  useEffect(() => {
    getUserDetails();
    GetPackagesDetails();
    getUserFavouritesDetails();
    GetRegisteredUser().then((response) => {
      setRegisterdUser(response);
    });
    GetMainCourt().then((response) => {
      setMainCourt(response);
    });
    Loyaltypoints();
  }, []);

  useEffect(() => {
    getUserBookingDetails();
    setUserDetails(userDetails);
  }, [userDetails]);

  var jsonObj = [];

  const DataNotFound = [
    {
      first_name: "Data Not Found",
    },
  ];

  const DataNotFoundCourt = [
    {
      main_court: "Data Not Found",
    },
  ];

  var jsonObj2 = [];
  const data2 = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Main Court",
        accessor: "main_court",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Address",
        accessor: "court_address",
        disableGlobalFilter: false,
        width: 65,
      },
    ],
    data: jsonObj2,
  };

  for (let i = 0; i < userFavourites?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["main_court"] = userFavourites[i].maincourt.courtName;
    item["court_address"] = userFavourites[i].maincourt.courtaddress;
    jsonObj2.push(item);
  }


  var jsonObj4 = [];
  const data4 = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Points/Slots",
        accessor: "points_slots",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Information",
        accessor: "information",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Date Time",
        accessor: "datetime",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Transaction",
        accessor: "get_points_from",
        disableGlobalFilter: false,
        width: 65,
      },
    ],
    data: jsonObj4,
  };

  for (let i = 0; i < loyaltyLogs?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["points_slots"] = (
      <div className={(loyaltyLogs[i]?.type == "deduct" || loyaltyLogs[i]?.logs?.log_type == "shared" || loyaltyLogs[i]?.logs[0]?.log_type == "free slots") ? "deductPack" : "rechargePack"}>
        {(loyaltyLogs[i]?.type == "deduct" || loyaltyLogs[i]?.logs?.log_type == "shared" || loyaltyLogs[i]?.logs[0]?.log_type == "free slots") ? "-" + loyaltyLogs[i]?.loyaltypoints + " Points" : "+" + loyaltyLogs[i]?.loyaltypoints + " Points"}
      </div>
    );
    item["information"] = (loyaltyLogs[i]?.type == "earned" && loyaltyLogs[i]?.logs[0]?.log_type == "shared") ? `From ${loyaltyLogs[i]?.logs[0]?.from} ${loyaltyLogs[i]?.booking != 0 ? '#' + loyaltyLogs[i]?.booking : ''}` : loyaltyLogs[i]?.logs[0]?.log_type == "free slots" ? "Redeemed" : loyaltyLogs[i]?.logs[0]?.log_type == "shared" ? `Sent to ${loyaltyLogs[i]?.logs[0]?.to}` : `Booking ID # ${loyaltyLogs[i]?.booking}`
    item["datetime"] = moment(loyaltyLogs[i].createdate.toString()).format("hh:mm A - DD/MM/YY");
    item["get_points_from"] = (loyaltyLogs[i]?.type == "earned" && loyaltyLogs[i]?.logs[0]?.log_type == "shared") ? loyaltyLogs[i]?.type : loyaltyLogs[i]?.logs[0]?.log_type

    jsonObj4.push(item);
  }

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Name...",
  };

  const handlePageClick = async (data) => {
    setSpinner(true);
    setUserBookingData([]);
    let currentPage = data.selected + 1;
    setInitialPage(data.selected);
    let newlimit = parseInt(
      localStorage.getItem("BookingListCurrentPageLimit")
    );
    localStorage.setItem("BookingListCurrentPage", currentPage);
    if (!searchData) {
      let data = JSON.parse(localStorage.getItem("searchdata"));
      const result = axios(
        `${environment.baseUrl}search-courtbooking?created_at_after=${data?.created_at_after ? data?.created_at_after : ""
        }&created_at_before=${data?.created_at_before ? data?.created_at_before : ""
        }&booking_date_after=${data?.booking_date_after ? data?.booking_date_after : ""
        }&booking_date_before=${data?.booking_date_before ? data?.booking_date_before : ""
        }&main_court=${mainCourtId ? mainCourtId : ""}&padel_court=${data?.padel_court ? data?.padel_court : ""
        }&booking_id=${data?.booking_id ? data?.booking_id : ""
        }&user=${id}&payment_status=${data?.payment_status ? data?.payment_status : ""
        }&page=${currentPage}&page_size=${newlimit}`
      ).then((response) => {
        if (response?.data?.count > 0) {
          setSpinner(false);
          setUserBookingData(response.data?.results);
          setpageCount(Math.ceil(response.data.count / limit));
        } else {
          alert("Data is not available for search");
          setSpinner(false);
        }
      });
    } else {
      let data = JSON.parse(localStorage.getItem("searchdata"));
      const result = axios(
        `${environment.baseUrl}search-courtbooking?created_at_after=${data?.created_at_after}&created_at_before=${data?.created_at_before}&booking_date_after=${data?.booking_date_after}&booking_date_before=${data?.booking_date_before}&main_court=${mainCourtId}&padel_court=${data?.padel_court}&booking_id=${data?.booking_id}&user=${id}&payment_status=${data?.payment_status}&page=${currentPage}&page_size=${newlimit}`
      ).then((response) => {
        if (response.data?.count > 0) {
          setSpinner(false);
          setUserBookingData(response.data?.results);
          setpageCount(Math.ceil(response.data?.count / limit));
        } else {
          setSpinner(false);
          // setUserBookingDetails([]);
          setpageCount(Math.ceil(response.data.count / limit));
        }
      });
    }
    setSpinner(false);
  };

  async function handleLimitChange(e) {
    localStorage.setItem("BookingListCurrentPageLimit", e.target.value);
    let currentPage = parseInt(localStorage.getItem("BookingListCurrentPage"));
    setInitialPage(0);
    await getUserBookingData(1, parseInt(e.target.value));
  }

  function mainCourtChangeHandler(e) {
    const filteredMainCourt = mainCourt?.filter(
      (item) => item["courtName"] === e.target.value
    );
    setMainCourtId(filteredMainCourt[0]?.id);
    GetPadelCourt().then((response) => {
      const padelCourt = [];
      let padelCourts = response;
      let padelCourtFilter = padelCourts.filter(function (padelCourts) {
        return padelCourts.maincourt.id === filteredMainCourt[0]?.id;
      });
      padelCourtFilter.map((data) => {
        padelCourt.push({ id: data.id, courtname: data.courtname });
      });
      setPadelCourtList(padelCourt);
    });
  }

  const handleResetSearchForm = () => {
    reset({});
    setSearchData(false);
    localStorage.removeItem("searchdata");
    getUserDetails();
  };

  const onSubmit = (data) => {
    setSpinner(true);
    setUserBookingData([]);
    localStorage.setItem("searchdata", JSON.stringify(data));
    setSearchData(true);
    let page = 1;
    let pageSize = parseInt(
      localStorage.getItem("BookingListCurrentPageLimit")
    );
    const SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data?.created_at_after}&created_at_before=${data?.created_at_before}&booking_date_after=${data?.booking_date_after}&booking_date_before=${data?.booking_date_before}&main_court=${mainCourtId}&padel_court=${data?.padel_court}&booking_id=${data?.booking_id}&user=${id}&payment_status=${data?.payment_status}&page=${page}&page_size=${pageSize}`;
    const result = axios.get(SearchUrl).then((response) => {
      setInitialPage(0);
      if (response.data?.count > 0) {
        setSpinner(false);
        setUserBookingData(response.data.results);
        setpageCount(Math.ceil(response.data.count / limit));
      } else {
        setSpinner(false);
        setUserBookingData([]);
        setpageCount(Math.ceil(response.data.count / limit));
      }
    });
  };

  let adminId = localStorage.getItem("UserId");
  let adminEmail = localStorage.getItem("Email");
  let LoggedUser = JSON.parse(localStorage.getItem("loggedUserDetails"));

  let createdBy = [{
    userId: adminId,
    userEmail: adminEmail,
    userFirstName: LoggedUser.first_name,
    userLastName: LoggedUser.last_name,
  }]

  const onAddPackSubmit = (data) => {

    const packages = [
      {
        title: "Custom Package",
        slot: parseInt(data.addslots),
        freeslot: 0,
      },
    ];
    const payload = {
      action: "recharge",
      package: JSON.stringify(packages),
      user: id,
      log: JSON.stringify([{ comment: data.addcmt }]),
      payment_status: "Pending",
      purchased_using: "admin",
      usedslot: parseInt(data.addslots),
      response_code: "14000",
      created_by: JSON.stringify(createdBy)
    };



    setSpinner(true);
    const packageUrl = `${environment.baseUrl}package-purchase`;
    const result = axios.post(packageUrl, payload).then((response) => {

      payload["purchase_id"] = response.data.PurchasedId;
      if (response.data.Message === "Package purchased successfully") {
        const transactionUrl = `${environment.baseUrl}package-slots-admin/`;
        const result = axios.post(transactionUrl, payload).then((response) => {

          if (response.status === 200) {
            if (response.data.Message === "Packages Added Successfully") {
              swal({
                title: "Success",
                text: "Package Added Successfully!",
                icon: "success",
                button: "Ok",
              });
            }
            reset2();
            GetPackagesDetails();
          }
        });
      }
    });
  };

  const onDelPackSubmit = (data) => {


    if (availableSlots >= parseInt(data.delslots)) {
      const packages = [
        {
          title: "Deleted Slots",
          slot: parseInt(data.delslots),
        },
      ];
      const payload = {
        action: "remove",
        package: JSON.stringify(packages),
        user: id,
        log: JSON.stringify([{ comment: data.delcmt }]),
        payment_status: "Pending",
        purchased_using: "admin",
        usedslot: parseInt(data.delslots),
        response_code: "14000",
        created_by: JSON.stringify(createdBy)
      };


      setSpinner(true);
      const packageUrl = `${environment.baseUrl}package-purchase`;
      const result = axios.post(packageUrl, payload).then((response) => {
        payload["purchase_id"] = response.data.PurchasedId;
        if (response.data.Message === "Package purchased successfully") {
          const transactionUrl = `${environment.baseUrl}package-slots-admin/`;
          const result = axios
            .post(transactionUrl, payload)
            .then((response) => {
              if (response.status === 200) {
                if (
                  response.data.Message ===
                  "Packages Slots Deleted Successfully"
                ) {
                  swal({
                    title: "Success",
                    text: "Slots Deleted SuccessFully ",
                    icon: "success",
                    button: "Ok",
                  });
                }
                reset3();
                GetPackagesDetails();
              }
            });
        }
      });
    } else {
      swal({
        title: "Warning",
        text: "Deleting Slots Exceeding Available Slots",
        icon: "warning",
        button: "Ok",
      });
    }
  };

  function handleEmailChange(e) {
    const filteredEmail = registerdUser?.filter(
      (item) => item["email"] === e.target.value
    );
    setUserId(filteredEmail[0]?.id);
  }

  const clear = (event) => {
    event.target.value = "";
  };

  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <Label
                style={{
                  fontFamily: "sans-serif",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Name:{" "}
              </Label>
              {"  "}
              <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                {userDetails.first_name === "null"
                  ? ""
                  : userDetails.first_name}{" "}
                {userDetails.last_name === "null" ? "" : userDetails.last_name}
              </Label>
            </div>
            <Row>
              <Col md={3} lg={3}>
                <Col md={3} lg={3}>
                  <img
                    className="topbar__avatar-img square-img"
                    src={
                      userDetails.profile_pic !== "null"
                        ? userDetails.profile_pic
                        : Ava
                    }
                    alt="avatar"
                  />
                </Col>
              </Col>
              <Col md={9} lg={9}>
                <Row>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Source:
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.source === "null" ? "" : userDetails.source}
                    </Label>
                  </Col>
                  <Col md={5} lg={5}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Email:{" "}
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.email === "null" ? "" : userDetails.email}
                    </Label>
                  </Col>
                  <Col md={3} lg={3}></Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Gender:{" "}
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.gender === "null" ? "" : userDetails.gender}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Phone No:{" "}
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.phone_no === "null"
                        ? ""
                        : userDetails.phone_no}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}></Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Games Played:{" "}
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.no_of_games === "null"
                        ? ""
                        : userDetails.no_of_games}
                    </Label>
                    {/* <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.complete_booking_matches === "null"
                        ? ""
                        : userDetails.complete_booking_matches}
                    </Label> */}
                  </Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Address:{" "}
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.address === "null"
                        ? ""
                        : userDetails.address}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}></Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      City:{" "}
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.city === "null" ? "" : userDetails.city}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      State:{" "}
                    </Label>
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.state === "null" ? "" : userDetails.state}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}></Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Country:
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.country === "null"
                        ? ""
                        : userDetails.country}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Role:
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userDetails.role === "null" ? "" : userDetails.role}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}></Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Package Slots:
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {availableSlots === undefined ? "0" : availableSlots}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Padelholic Level:
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {padelholicLevel}
                    </Label>
                  </Col>
                  <Col md={4} lg={4}></Col>
                  <Col md={4} lg={4}>
                    <Label
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Current Loyaltypoints:
                    </Label>{" "}
                    <Label style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                      {userLoyaltyPointData?.loyaltyavailable === "null" ? "" : userLoyaltyPointData?.loyaltyavailable}
                    </Label>
                  </Col>

                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* ------------------------------------------- */}

      <Col md={12} style={{ marginTop: "10px" }}>
        <Card>
          <CardBody>
            <Row>
              <Col md={12} lg={12}>
                <Card>
                  <CardBody>
                    <form onSubmit={handleSubmit2(onAddPackSubmit)}>
                      <Row>
                        <Col sm={4} md={4} lg={4}>
                          <div className="form product-edit">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Add Packages
                              </span>
                              <div className="form__form-group-field">
                                <input
                                  type="number"
                                  placeholder="Enter no. of slots"
                                  name="addslots"
                                  {...register2("addslots", { required: true })}
                                />
                              </div>
                              {errors2.addslots && (
                                <span className="error-msg">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={4} md={4} lg={4}>
                          <div className="form product-edit">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Comments
                              </span>
                              <div className="form__form-group-field">
                                <input
                                  type="text"
                                  placeholder="Comments"
                                  name="addcmt"
                                  {...register2("addcmt", { required: true })}
                                />
                              </div>
                              {errors2.addcmt && (
                                <span className="error-msg">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col
                          sm={4}
                          md={4}
                          lg={4}
                          className="cal-top-submit-btn"
                        >
                          <button
                            className="justpadel-buttons-save"
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </form>

                    <form onSubmit={handleSubmit3(onDelPackSubmit)}>
                      <Row>
                        <Col sm={4} md={4} lg={4}>
                          <div className="form product-edit">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Delete Packages
                              </span>
                              <div className="form__form-group-field">
                                <input
                                  type="number"
                                  placeholder="Enter no. of slots"
                                  name="delslots"
                                  {...register3("delslots", { required: true })}
                                />
                              </div>
                              {errors3.delslots && (
                                <span className="error-msg">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={4} md={4} lg={4}>
                          <div className="form product-edit">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Comments
                              </span>
                              <div className="form__form-group-field">
                                <input
                                  type="text"
                                  placeholder="Comments"
                                  name="delcmt"
                                  {...register3("delcmt", { required: true })}
                                />
                              </div>
                              {errors3.delcmt && (
                                <span className="error-msg">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col
                          sm={4}
                          md={4}
                          lg={4}
                          className="cal-top-submit-btn"
                        >
                          <button
                            className="justpadel-buttons-save"
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Col md={12} style={{ marginTop: "10px" }}>
        <Card>
          <CardBody>
            <div>
              <div className="react-table__wrapper hide-table-costomizer">
                <div className="card__title">
                  <h5 className="bold-text">TRANSACTION LOG'S</h5>
                </div>
                <UserPackageTransactionDetails userId={id} />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md={12} style={{ marginTop: "10px" }}>
        <Card>
          <CardBody>
            <div>
              <div className="react-table__wrapper hide-table-costomizer">
                <div className="card__title">
                  <h5 className="bold-text">LOYALTYPOINTS LOG'S</h5>
                </div>
                <ReactTableBase
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={data4.columns}
                  data={
                    data4.data.length === 0 ? DataNotFoundCourt : data4.data
                  }
                  tableConfig={tableConfig}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md={12} lg={12} style={{ marginTop: "10px" }}>
        <Card>
          <CardBody>
            <UserBookingDetailsByUserId />
          </CardBody>
        </Card>
      </Col>

      <Col md={12} lg={12} style={{ marginTop: "10px" }}>
        <Card>
          <CardBody>
            <UserBookingDetailsByEmail />
          </CardBody>
        </Card>
      </Col>

      <Col md={12} lg={12} style={{ marginTop: "10px" }}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper hide-table-costomizer">
              <div className="card__title">
                <h5 className="bold-text">User Favourites Court</h5>
              </div>
              <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                handleClickIsResizable={handleClickIsResizable}
                isResizable={isResizable}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
              />
            </div>
            <ReactTableBase
              key={withSearchEngine ? "searchable" : "common"}
              columns={data2.columns}
              data={data2.data.length === 0 ? DataNotFoundCourt : data2.data}
              tableConfig={tableConfig}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default UserDetailsCard;
