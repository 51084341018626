import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLogged: false,
    loggedUser: null
};

export const authSlice = createSlice({
    name: 'authslice',
    initialState,
    reducers: {
        logged: (state, action) => {
            state.isLogged = true;
            state.loggedUser = action.payload
        },
        userLogout: (state) => {
            state.isLogged = false;
            state.loggedUser = null
        },
    },
})

export const { logged, userLogout } = authSlice.actions

export default authSlice.reducer