/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import { Col, Button, Card, CardBody } from "reactstrap";
import axios from "../../../customAxios";
import { useNavigate } from "react-router-dom";
import { environment } from "../../env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPhone,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fabTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { autofill } from "redux-form";
import { Link } from "react-router-dom";
import "./CourtBooking.css";


const UsersListCard = () => {
  const [mainCourts, setmainCourts] = useState([]);
  const [userRole, setUserRole] = useState();
  const [role, setRole] = useState();
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);

  const mainCourtsUrl = `${environment.baseUrl}maincourts`;
  const getMainCourt = async () => {
    const result = await axios(mainCourtsUrl);
    console.log(result.data);
    setmainCourts(result.data);
  };

  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);
    });
  };

  useEffect(() => {
    getMainCourt();
    getRole();
    setUserRole(localStorage.getItem("Role"));
    if (localStorage.getItem("Token")) {
      console.log("True");
    } else {
      alert("You are not logged In. Please LogIn");
      navigate("/");
    }
  }, []);

  const setPermissions = (roledata) => {
    // const localrole = "admin";
    const localrole = localStorage.getItem("Role");
    console.log("Local Added Role", localrole);
    let newRoleId;
    console.log(roledata.role_name);

    if (localrole === roledata.role_name) {
      console.log(roledata.permissions);
      let permission = JSON.parse(roledata.permissions);
      console.log(permission);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "manual_bookings";
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }
    console.log("Role Id", newRoleId);
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
    console.log(editing);
  };

  const handleSelect = (id) => {
    navigate(`/book_court/${id}`);
  };
  const handleGotoCart = () => {
    navigate("/addon_booking");
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody style={{boxShadow: '0 0 10px #e0e0e0',marginBottom:20}}>
          <div className="go-to-cart-btn-container">
            <div
              className="justpadel-buttons"
              color="warning"
              onClick={handleGotoCart}
            >
              Go to Cart
            </div>
          </div>
          <div>
            <div className="banner-container">
              <div className="booking-containers">
                {mainCourts &&
                  mainCourts?.map((data, index) => {
                    return (
                      <div className="courts">
                        <img
                          src={data?.maincourtimage}
                          alt={data?.maincourtimage}
                          className="padelImg"
                        />
                        <div className="court-details-container">
                          <div className="court-details">
                            <div className="court-inner">
                              <div>
                                <h4>{data?.courtName}</h4>
                              </div>
                              <div className="court-icons">
                                <FontAwesomeIcon
                                  icon={faPhone}
                                  className="faPhone"
                                />
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                  className="faPaperPlane"
                                />
                              </div>
                            </div>
                            <div className="court-count">
                              <h5>
                                {data?.padelCourts} {data.courttype.courttype}{" "}
                                Courts
                              </h5>
                            </div>
                            {data?.timeslot.split(",").map((datas, index) => {
                              return (
                                <div className="court-time">
                                  {index === 0 ? (
                                    <FontAwesomeIcon
                                      icon={faClock}
                                      className="faClock"
                                    />
                                  ) : (
                                    <span style={{ paddingLeft: "15px" }} />
                                  )}
                                  <h5>{datas}</h5>
                                </div>
                              );
                            })}
                            {/* </div> */}
                            <div className="court-has">
                              <small>Outdoor</small>
                              {data?.features?.split(",").map((feature) => (
                                <small>{feature}</small>
                              ))}
                            </div>

                            {data?.booking_facility === "Call Now Facility" ? (
                              <a
                                href={`tel:${data.contact_number}`}
                                className="book-btn"
                              >
                                <h5>CALL NOW</h5>
                              </a>
                            ) : (
                              <Link
                                className="book-btn"
                                onClick={() => handleSelect(data.id)}
                              >
                                <h5>BOOK COURT</h5>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

        
        </CardBody>
      </Card>
    </Col>
  );
};
export default UsersListCard;
