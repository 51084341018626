/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col,Row, Card, CardBody, Button,
} from 'reactstrap';
import axios from '../../customAxios';
import { useNavigate } from 'react-router-dom';
import { environment } from '../env';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import { AiFillEye, AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import moment from 'moment';
import QuickPayModel from './QuickPayModel';

const QuickPayDetails = () => {
  const [quickPayDetails, setQuickPayDetails] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [view, setView] = useState(false);
  const [transactionLog, setTransactionLog] = useState();
  const [category, setCategory] = useState();

  const quickPayDetailsURL = `${environment.baseUrl}quicks-pay`;
  const navigate = useNavigate();

  const getUser = async () => {
    const result = await axios(quickPayDetailsURL);
    setQuickPayDetails(result.data);
    
  };

  useEffect(() => {
    getUser();
    if (localStorage.getItem('Token')) {
    } else {
      alert('You are not logged In. Please LogIn');
      navigate('/');
    }
  }, []);


  var jsonObj2 = [];
  for (let i = 0; i < quickPayDetails.length; i++) {
    var item = {}
    item["id"] = i + 1;
    item["email"] = quickPayDetails[i].email.toString();
    item["first_name"] = quickPayDetails[i].name === "null" ? '' : quickPayDetails[i].name.toString();
    item["phone_no"] = <div className="right-space user-phone">{quickPayDetails[i].phone === "null" ? '' : quickPayDetails[i].phone}</div>;
    item["role"] = <div className="right-space">{quickPayDetails[i].amount} AED</div>;
    item["date"] = <div className="right-space">{moment(quickPayDetails[i].created_date).format("DD-MM-YYYY")} <br />
      {moment(quickPayDetails[i].created_date.split('T')[1], "HH:mm:ss").format("hh:mm A")}
    </div>;
    item["category"] = <div className="right-space">{quickPayDetails[i].subject}</div>;
    item["location"] = <div className="right-space">{quickPayDetails[i].location}</div>;

    item["payment_status"] = <div className="right-space text-capitalize">{quickPayDetails[i].payment_status === "completed" ? <h3 className='amountStatusCompleted'>Completed</h3> : <h3 className='amountStatusCancelled mt-1'>Pending</h3>}</div>;
    item["view"] = <div className="right-space">

      <button className='modal-view-btn' onClick={()=>OpenModal(quickPayDetails[i].transaction_log, quickPayDetails[i].additional_comments)}>
        <AiFillEye style={{ fontSize: 20,  height: "19px", width: "19px" }} />
      </button>
    </div>;
    jsonObj2.push(item);
  }

  const handleViewDetails = (id) => {
    navigate(`user_detailss/${id}`);
  }

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };

  const newdata = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 10,
      },
      {
        Header: 'Name',
        accessor: 'first_name',
        disableGlobalFilter: false,
        width: 70,
      },
      {
        Header: 'Category',
        accessor: 'category',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Location',
        accessor: 'location',
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableGlobalFilter: false,
        width: 100,
      },
      {
        Header: 'Phone No',
        accessor: 'phone_no',
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: 'Amount',
        accessor: 'role',
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: 'Created Time',
        accessor: 'date',
        disableGlobalFilter: true,
        width: 65,
      },
      
      {
        Header: 'Payment Status',
        accessor: 'payment_status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 55,
      },
      {
        Header: 'View',
        accessor: 'view',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      }

    ],
    data: jsonObj2
  };

  const OpenModal = (transactionLogData, paymentCategory) => {
    setView();
    setTransactionLog();
    setCategory();
    setView(!view);
    if(transactionLogData !== "No Transaction log"){
      setTransactionLog(JSON.parse(transactionLogData)[0]);
      setCategory(paymentCategory);
    }else{
      setTransactionLog({"Data":"No Transaction log"})
    }
  }
  const CloseModal = () => {
    setView(false);
  }

  return (
    <Fragment>
       <Row>
        <Col md={12}>
          <h3 className="page-title">Quick Pay</h3>
        </Col>
      </Row>
    <Col md={12} lg={12}>
      <QuickPayModel transactionLog={transactionLog} category={category} show={view} CloseModal={CloseModal} color="primary" header title="Transaction Details" />
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>

          <div className="hover-effect table-id-width">
            <ReactTableBase
              key={withSearchEngine ? 'searchable' : 'common'}
              columns={newdata.columns}
              data={newdata.data}
              tableConfig={tableConfig}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
    </Fragment>
  )
}

export default QuickPayDetails