import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Spinner,
} from "reactstrap";
import JoditEditor from "jodit-react";
import axios from "../../../customAxios";
import S3FileUpload from "react-s3";
import { useNavigate } from "react-router-dom";
import { environment } from "../../env";
import PhoneInput, {
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { RMIUploader } from "react-multiple-image-uploader";
import ImageUploader from "./ImageUploader";

const dataSources = [];

const bookingFacilityOptions = [
  { value: "Book Now Facility", label: "Book Now Facility" },
  { value: "Call Now Facility", label: "Call Now Facility" },
];

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const CourtCard = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const [resources, setResources] = useState([]);
  const [courtTypeList, setCourtTypeList] = useState();
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const timesAsOptions = times.map((time) => ({
    value: time,
    label: time,
  }));

  const onSubmit = async (formData) => {
    formData.non_peak_hours_start = formData.non_peak_hours_start.value;
    formData.non_peak_hours_end = formData.non_peak_hours_end.value;
    setIsLoading(true);
    if (resources.length <= 0) {
      setImageError(true);
      setIsLoading(false);
      return;
    }

    var letFilter = [];
    formData?.filter?.map((filteredData) => {
      letFilter.push(filteredData.value);
    });

    console.log("111",formData.maincourtimage[0])

    let maincourtImg;

    await S3FileUpload.uploadFile(formData.maincourtimage[0], config)
      .then((data) => {
        maincourtImg = data.location;
      })
      .catch((err) => {
        console.log("Error Info: ", err);
      });

    formData.maincourtimage = maincourtImg;
    console.log(formData.maincourtimage)
    formData.courttype_multiple = letFilter.toString();
    // formData.maincourt_about_images = JSON.stringify(resources);
    formData.booking_facility = formData.booking_facility.value;
    formData.courttype = formData.courttype.value;

    var UploadedImages = [];
    resources.map(async (img) => {
      if ("resource" in img) {
        UploadedImages.push({ resource: img.resource });
        if (resources?.length === UploadedImages?.length) {
          console.log("if condition");
          submitMainCourt(formData, UploadedImages);
        }
      } else {
        await S3FileUpload.uploadFile(img.file, config)
          .then((data) => {
            UploadedImages.push({ resource: data.location });
            if (resources?.length === UploadedImages?.length) {
              console.log("else condition");
              submitMainCourt(formData, UploadedImages);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("Error Info: ", err);
          });
      }
    });
  };

  const submitMainCourt = (formData, UploadedImages) => {
    formData.maincourt_about_images = JSON.stringify(UploadedImages);

    const mainCourtUrl = `${environment.baseUrl}maincourts`;
    axios
      .post(mainCourtUrl, formData)
      .then((response) => {
        if (response.data.Message === "Main Court Added Successfully") {
          swal({
            text: "Main Court added Successfully",
            icon: "success",
            buttons: true,
          }).then(function () {
            window.location = "/list_main_court";
          });
          setIsLoading(false);
        } else {
          swal({
            title: "Error.. While adding main court",
            text: JSON.stringify(response.data),
            icon: "error",
            buttons: true,
          }).then(function () {
            console.log("");
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        swal({
          text: "Error.. While adding main court",
          icon: "error",
          buttons: true,
        }).then(function () {
          console.log(err);
        });
        setIsLoading(false);
      });
  };

  const GetCategory = async () => {
    const courtTypeUrl = `${environment.baseUrl}courttypes`;
    const result = await axios(courtTypeUrl);
    var newCourtType = [];
    result.data.map((ctdata) => {
      newCourtType.push({ label: ctdata.courttype, value: ctdata.id });
    });
    setCourtTypeList(newCourtType);
  };

  const setImage = (imagesList) => {
    if (imagesList?.length > 0) {
      setImageError(false);
    } else {
      setImageError(true);
    }
    setResources(imagesList);
  };

  
  useEffect(() => {
    GetCategory();
  }, []);

  const BookingFacilitySelected = watch("booking_facility");

  return (
    <Col md={12} lg={12}>
      {isLoading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 style={{ color: "#acacac" }} className="bold-text">
              Add New Main Court Here {JSON.stringify(imageError)}
            </h5>
          </div>
          <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Main Court Name</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Main Court Name"
                    {...register("courtName", { required: true })}
                  />
                </div>
                {errors.courtName && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Name, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Main Court Address
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Main Court Address"
                    {...register("courtaddress", { required: true })}
                  />
                </div>
                {errors.courtaddress && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Address, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Latitude</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Main Court Latitude"
                    step="any"
                    {...register("latitude", { required: true })}
                  />
                </div>
                {errors.latitude && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Latitude, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Longitude</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Main Court Longitude"
                    step="any"
                    {...register("longitude", { required: true })}
                  />
                </div>
                {errors.longitude && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Longitude, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Price</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Main Court Price"
                    {...register("price", { required: true })}
                  />
                </div>
                {errors.price && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Main Court Price, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Non Peak Hours Start
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="non_peak_hours_start"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        options={timesAsOptions}
                        className=" w-100"
                        placeholder="Non Peak Hours Start"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.non_peak_hours_start && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Non Peak Hours Start, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Non Peak Hours End
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="non_peak_hours_end"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        options={timesAsOptions}
                        className=" w-100"
                        placeholder="Non Peak Hours End"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.non_peak_hours_end && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Non Peak Hours End, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Peak Hours Price Firstslot
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Peak Hours Price Firstslot"
                    {...register("peak_hours_price_firstslot", {
                      required: true,
                    })}
                  />
                </div>
                {errors.peak_hours_price_firstslot && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Peak Hours Price Firstslot, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Peak Hours Price Secondslot
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Peak Hours Price Secondslot"
                    {...register("peak_hours_price_secondslot", {
                      required: true,
                    })}
                  />
                </div>
                {errors.peak_hours_price_secondslot && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter The Peak Hours Price Secondslot, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">About</span>
                <div className="form__form-group-field">
                  <textarea
                    placeholder="About"
                    {...register("about", { required: true })}
                  />
                </div>
                {errors.about && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter About Main Court, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Learn More </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="learn_more_text"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <JoditEditor
                        ref={ref}
                        value={value}
                        tabIndex={1}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                {errors.learn_more_text && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Content of Main Court, It Should Not be Empty"
                    }
                  />
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Court Position</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="add court position"
                    style={{ borderRadius: 5, borderColor: "#ccc" }}
                    {...register("position", { required: true })}
                  />
                </div>
                {errors.position && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Position, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Opening and Closing Time
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Opening and Closing Time"
                    {...register("timeslot", { required: true })}
                  />
                </div>
                {errors.timeslot && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Opening and Closing Time, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Features</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Features"
                    {...register("features", { required: true })}
                  />
                </div>
                {errors.features && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Features, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Court Type</span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="courttype"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        options={courtTypeList}
                        className="text-uppercase w-100"
                        placeholder="Select court type"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.courttype && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court Court-Type, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">City</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="City"
                    {...register("city", { required: true })}
                  />
                </div>
                {errors.city && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court City, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Country</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Country"
                    {...register("country", { required: true })}
                  />
                </div>
                {errors.country && (
                  <ErrorMessageComponent
                    message={
                      "Please Enter Main Court country, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group" style={{ marginTop: 20 }}>
                <span
                  className="form__form-group-label "
                  style={{ fontSize: 15 }}
                >
                  Court Type for Filter
                </span>
                <div
                  className="form__form-group-field"
                  style={{ flexDirection: "column" }}
                >
                  <Controller
                    control={control}
                    name="filter"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        options={courtTypeList}
                        closeMenuOnSelect={true}
                        isMulti
                        className="text-uppercase w-100"
                        placeholder="Select Filtering Court Category"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.filter && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Filter Category, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Booking Facility</span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="booking_facility"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        options={bookingFacilityOptions}
                        className="text-uppercase w-100"
                        placeholder="Select Booking Facility"
                        onChange={onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
                {errors.booking_facility && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Booking Facility, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              {BookingFacilitySelected?.value === "Call Now Facility" && (
                <div className="form__form-group">
                  <span className="form__form-group-label">Contact Number</span>
                  <div className="form__form-group-field">
                    <Controller
                      control={control}
                      name="phone_no"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <PhoneInput
                          defaultCountry="AE"
                          className="add-client-phone w-100"
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  {errors.phone_no && (
                    <ErrorMessageComponent
                      message={
                        "Please Select Main Court Booking Facility Phone No, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
              )}

              <div className="form__form-group">
                <span className="form__form-group-label">Upload Image</span>
                <div className="form__form-group-field">
                  <input
                    type="file"
                    className="form__form-group-field"
                    {...register("maincourtimage", { required: true })}
                  />
                </div>
                {errors.maincourtimage && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Image, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Video URL</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    className="form__form-group-field"
                    {...register("video_url", { required: true })}
                  />
                </div>
                {errors.video_url && (
                  <ErrorMessageComponent
                    message={
                      "Please Select Main Court Video URL, It Should Not be Empty"
                    }
                  />
                )}
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">About Us Images</span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="maincourt_about_images"
                    rules={{ required: false }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => <ImageUploader setImage={setImage} />}
                  />
                </div>
                {/* { (resources?.length <= 0) && ( <ErrorMessageComponent message={"Please Add Main Court About Images, It Should Not be Empty"} />)} */}
                {imageError && (
                  <ErrorMessageComponent
                    message={
                      "Please Add Main Court Image, It Should Not be Empty"
                    }
                  />
                )}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <button
                  className="justpadel-buttons-save"
                  color="primary"
                  type="submit"
                  disabled={resources?.length <= 0}
                >
                  Save
                </button>
                <div className="justpadel-buttons-cancel">Cancel</div>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CourtCard;

const ErrorMessageComponent = ({ message }) => {
  return (
    <div color="danger" className="form__form-group-error">
      <p style={{ color: "#dc3545" }}>
        <span className="bold-text">Warning! </span>
        {message}
      </p>
    </div>
  );
};
