import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Router, { Pages } from './Router';
import ScrollToTop from './ScrollToTop';
import '../../scss/app.scss'
const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoading(false);
      setTimeout(() => setIsLoaded(true), 500);
    });
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
          <Router />
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
