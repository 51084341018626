import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Row } from "reactstrap";
import axios from "../../../customAxios";
import { useNavigate, Link } from "react-router-dom";
import { environment } from "../../env";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import swal from "sweetalert";

const SlidesListV2 = () => {

    const [slidesV2, setSlidesV2] = useState([]);
    const navigate = useNavigate();
    const [roleId, setRoleId] = useState();
    const [editing, setEditing] = useState(false);
    const [deleteing, setDeleteing] = useState(false);
    const [reload, setReload] = useState(false);
    const [role, setRole] = useState();
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);

    const slidesUrl = `${environment.baseUrl}getslides-v2`;

    const getCourtType = async () => {
        const result = await axios(slidesUrl);
        console.log(result.data);
        setSlidesV2(result.data);
    };

    const getRole = () => {
        const localrole = localStorage.getItem("Role");
        const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
        const result = axios.get(getRoleUrl).then((response) => {
            setRole(response.data);
            setPermissions(response.data);
        });
    };

    useEffect(() => {
        getRole();
        if (localStorage.getItem("Token")) {
            console.log("True");
        } else {
            alert("You are not logged In. Please LogIn");
            navigate("/");
        }

        getCourtType();
    }, []);

    const setPermissions = (roledata) => {
        const localrole = localStorage.getItem("Role");
        let newRoleId;
        console.log(roledata.role_name);

        if (localrole === roledata.role_name) {
            console.log(roledata.permissions);
            let permission = JSON.parse(roledata.permissions);
            console.log(permission);
            const rl = Object.values(permission).forEach((a) => {
                Object.keys(a).forEach((e) => {
                    var page_name = "slides"
                    if (e === page_name) {
                        // eslint-disable-next-line no-unused-expressions
                        newRoleId = a[e];
                    }
                });
            });
        } else {
            console.log("false");
        }
        console.log("Role Id", newRoleId);
        if (
            newRoleId === 2 ||
            newRoleId === 3 ||
            newRoleId === "2" ||
            newRoleId === "3"
        ) {
            setEditing(true);
            setReload(!reload);
        }
        if (newRoleId === 3 || newRoleId === "3") {
            setDeleteing(true);
            setReload(!reload);
        }
        console.log(editing);
    };

    var jsonObj = [];
    console.log("NEw JSON ", jsonObj);
    for (let i = 0; i < slidesV2.length; i++) {
        var item = {}
        item["id"] = i + 1;
        item["image"] = <div className="right-space"><img src={slidesV2[i].image_url} style={{ width: 180, borderRadius: 5 }} alt={slidesV2[i].image_url} /></div>;
        item["redirectUrl"] = <div className="right-space">{slidesV2[i].redirect_url}</div>;
        item["title"] = <div className="right-space">{slidesV2[i].title}</div>;
        item["sub_title"] = <div className="right-space">{slidesV2[i].sub_title}</div>;
        item["button_text"] = <div className="right-space">{slidesV2[i].button_text}</div>;
        if (deleteing === true) {
            item["link"] = <div className="right-space"><div onClick={() => handleEdit(slidesV2[i].id)} className="modal-edit-btn"><EditOutlineIcon style={{ fontSize: 20 }} /> </div></div>;
            item["delete"] = <div className="right-space"><div className="modal-delete-btn" onClick={() => handleDelete(slidesV2[i].id)}><DeleteOutlineIcon style={{ fontSize: 20 }} /></div></div>;
        } else if (editing === true) {
            item["link"] = <div className="right-space"><div onClick={() => handleEdit(slidesV2[i].id)} className="modal-edit-btn"><EditOutlineIcon style={{ fontSize: 20 }} /> </div></div>;
        }
        jsonObj.push(item);
    }

    const data = {
        columns: [
            {
                Header: '#',
                accessor: 'id',
                disableGlobalFilter: true,
                width: 65,
            },
            {
                Header: "Images",
                accessor: "image",
                disableGlobalFilter: true,
                width: 180,
            },
            {
                Header: "Redirect Url",
                accessor: "redirectUrl",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "Title",
                accessor: "title",
                disableGlobalFilter: true,
                width: 90,
            },
            {
                Header: "Sub Title",
                accessor: "sub_title",
                disableGlobalFilter: true,
                width: 140,
            },
            {
                Header: "Button Text",
                accessor: "button_text",
                disableGlobalFilter: true,
                width: 140,
            },
            {
                Header: "Edit",
                accessor: "link",
                disableGlobalFilter: true,
                width: 60,
            },
            {
                Header: "Delete",
                accessor: "delete",
                disableGlobalFilter: true,
                width: 60,
            },
        ],
        rows: jsonObj,
    };

    function handleEdit(slideId) {
        navigate(`/edit_slides-v2/${slideId}`);
    }
    const handleAddSlider = () => {
        navigate("/add_slides-v2");
    }

    const handleDelete = (id) => {

        const slidesDeleteUrl = `${environment.baseUrl}deleteslides-v2/${id}/`;

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Slide!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios(slidesDeleteUrl).then((response) => {
                    console.log(response);
                    setSlidesV2(() => slidesV2.filter((slide) => slide.id !== id));
                })
                swal("Your Slide has been deleted!", {
                    icon: "success",
                });
            } else {
                swal("Your Slide is safe!");
            }
        });
    };

    const handleAddSlides = () => {
        navigate("/slides");
    };
    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Name...',
    };

    return (
        <>
            <Col md={12} lg={12}
                style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'flex-end',
                }}>
                {deleteing ? (
                    <div className="justpadel-buttons btn-position" onClick={() => handleAddSlider()}>Add Slider</div>
                ) : (
                    ""
                )}
            </Col>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="react-table__wrapper hide-table-costomizer">
                            <ReactTableCustomizer
                                handleClickIsSortable={handleClickIsSortable}
                                handleClickWithPagination={handleClickWithPagination}
                                handleClickWithSearchEngine={handleClickWithSearchEngine}
                                handleClickIsResizable={handleClickIsResizable}
                                isResizable={isResizable}
                                isSortable={isSortable}
                                withPagination={withPagination}
                                withSearchEngine={withSearchEngine}
                            />
                        </div>
                        <div className="hover-effect table-id-width">
                            <ReactTableBase
                                key={withSearchEngine ? 'searchable' : 'common'}
                                columns={data.columns}
                                data={data.rows}
                                tableConfig={tableConfig}
                            />
                        </div>

                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default SlidesListV2